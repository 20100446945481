<template>
  <div class="root" :class="{ home: isHome }">
    <div class="con flex_align flex_between">
      <router-link to="/"
        ><div
          class="img_box"
          :style="{ 'background-image': `url(${logoUrl})` }"
        ></div
      ></router-link>
      <div class="nav_box">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :text-color="textColor"
          :active-text-color="activeTextColor"
        >
          <el-menu-item index="meiyan">美研</el-menu-item>
          <el-menu-item index="riben">日本</el-menu-item>
          <el-menu-item index="yingguo">英国</el-menu-item>
        </el-menu>
      </div>
      <div class="person_box flex_align" v-if="isLogin">
        <div class="block avatar">
          <el-avatar :size="40" :src="infor.head_link"></el-avatar>
        </div>
        <el-dropdown trigger="click" class="dropdown">
          <span class="el-dropdown-link">
            {{ infor.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdown">
            <router-link to="/personal" v-if="user_rule == 2 || user_rule == 3"
              ><el-dropdown-item icon="iconfont xhguser"
                >账户设置</el-dropdown-item
              ></router-link
            >
            <router-link to="/order" v-if="user_rule !== 7 || user_rule == 1"
              ><el-dropdown-item icon="iconfont xhgorder"
                >订单中心</el-dropdown-item
              ></router-link
            >
            <router-link to="/financial" v-if="user_rule == 7 || user_rule == 1"
              ><el-dropdown-item icon="iconfont xhgfinance"
                >财务中心</el-dropdown-item
              ></router-link
            >
            <!-- <el-dropdown-item to="/financial" icon="iconfont xhgfinance">财务中心</el-dropdown-item> -->
            <el-dropdown-item icon="iconfont xhgquite" @click.native="logout()"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else class="login_box" @click="login_if()">登录/注册</div>
    </div>
    <!-- 登录弹窗 -->
    <div v-if="$store.state.login">
      <login></login>
    </div>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
export default {
  name: "",
  components: { login },
  props: {
    isHome: {
      //是否首页
      type: Boolean,
      default: false,
    },
    isLogin: {
      //是否登录
      type: Boolean,
      default: false,
    },
    infor: {
      //用户信息
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeIndex: "meiyan",
      logoUrl: require("./image/yellowLogo.png"),
      textColor: "#333",
      activeTextColor: "#d99d66",
      circleUrl: require("./image/whiteLogo.png"),
      account: {
        name: "",
        url: "",
      },
      user_rule: 2,
    };
  },
  watch: {
    isHome(val) {
      this.homeHeader();
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.homeHeader();
    let source = this.$cookie.get("source")
    // 有业务线就重新赋值
    if(source){
      this.activeIndex = source;
      console.log(this.$route)

    }
    this.setSource(this.activeIndex);
    this.initFun();
  },
  methods: {
    logout() {
      this.$router.push("/");
      this.$cookie.remove("infor");
      this.$cookie.remove("token");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.setSource(key);
      this.activeIndex = key
      this.$router.push(`/home/${key}`)
    },
    setSource(val) {
      // this.activeIndex=val
      this.$cookie.set("source", val);
    },
    //登录框显示或者隐藏
    login_if() {
      this.$store.state.login = !this.$store.state.login;
    },
    //判断首页头部样式
    homeHeader() {
      if (this.isHome) {
        this.logoUrl = require("./image/whiteLogo.png");
        this.textColor = "#fff";
        this.activeTextColor = "#fff";
      } else {
        this.logoUrl = require("./image/yellowLogo.png");
        this.textColor = "#333";
        this.activeTextColor = "#d99d66";
      }
    },
    initFun() {
      this.user_rule = this.$cookie.get("user_rule");
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
a {
  list-style: none;
  text-decoration: none;
}
.root {
  box-shadow: 0px 2px 14px 0px rgba(112, 96, 94, 0.08);
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.home {
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  .login_box {
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dropdown,
  /deep/ .el-icon-arrow-down:before {
    color: #fff;
  }
}
.home.root {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
}
.dropdown {
  z-index: 3 !important;
}
.con {
  width: 1200px;
  height: 94px;
  margin: 0 auto;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.img_box {
  width: 150px;
  height: 54px;
  background: url(./image/yellowLogo.png) no-repeat;
  background-size: 100%;
}
.login_box {
  padding: 9px 20px;
  color: #fff;
  border-radius: 18px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
  background: #d09f6e;
}
.avatar {
  margin-right: 10px;
}
/deep/ .el-icon-arrow-down:before {
  content: "\e790";
  color: #333;
}

.dropdown {
  cursor: pointer;
}
// 改变导航默认样式
.el-menu--horizontal > .el-menu-item {
  font-weight: 600;
}
/deep/ .el-menu-item {
  padding: 0 41px;
  font-size: 20px;
}
/deep/ .el-menu.el-menu--horizontal {
  border: none;
}
/deep/ .el-menu--horizontal > .el-menu-item {
  height: 35px;
  line-height: 35px;
  border: none;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border: none;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active:after {
  content: "";
  width: 36px;
  height: 3px;
  border-radius: 8px;
  background: linear-gradient(to right, #ebc99d, #d59a64);
  display: block;
  margin-left: 2px;
}
.home /deep/ .el-menu--horizontal > .el-menu-item.is-active:after {
  content: "";
  background: #fff !important;
}
/deep/ .el-menu {
  background: none;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
}
// 修改下拉列表样式
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #606266;
}
.el-menu-item.is-disabled {
  opacity: 1;
}
/deep/ .el-dropdown-menu__item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC",
    "Microsoft YaHei", "微软雅黑", sans-serif;
        display: flex;
    align-content: center;
}
</style>
