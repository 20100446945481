<template>
  <div>
    <!-- 尾部上半部分 -->
    <div class="foots">
      <!-- logo部分 -->
      <div class="left_icon">
        <img src="./images/logo.png" alt="" />
      </div>
      <div class="right_text">
        <div class="right_text_one">
          <p>咨询电话</p>
          <div>400-897-1616（中国）</div>
          <div>+1 847-737-1414（美国）</div>
        </div>
        <div class="right_text_one" style="margin-top: 30px">
          <p>服务时间</p>
          <div>周一至周六：09:30 - 18:30</div>
        </div>
      </div>
      <div class="right_text" style="width: 40%">
        <div class="right_text_one">
          <p>联系我们</p>
          <div class="china_total">
            中国总部： 北京市朝阳区朝外大街1号京广中心商务楼10层1003
          </div>
        </div>
        <div class="right_text_one">
          <div class="uk_total">
            美国总部：318W.Half Day Road Suite 211Buffalo Grove,iL 60089,USA.
          </div>
        </div>
      </div>
    </div>
    <!-- 底部备案 -->
    <div class="footes">
      <div class="footes_bottom">
        <span> Beliwin Company @ 2016 . Privacy Polocy 京ICP备16012183号 </span
        ><br />
        彬彬国际教育咨询（北京）有限公司版权所有 beliwin.com 彬彬留学京公网安备
        京公网安备 11010502034288号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.foots {
  width: 100%;
  height: 320px;
  background: #424242;
  display: flex;
  justify-content: center;
  padding-top: 56px;
  .left_icon {
    width: 33%;
    height: 54px;
    text-align: right;
    padding-right: 153px;
    img{
      width: 150px;
    }
  }
  .right_text_one {
    p {
      font-size: 17px;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 1px;
    }
    div {
      width: 390px;
      font-size: 15px;
      font-weight: 400;
      color: #cccccc;
      line-height: 32px;
    }
    .china_tatal {
      width: 432px;
      height: 60px;
      font-size: 16px;
      font-weight: 400;
      color: #cccccc;
      line-height: 26px;
    }
    .uk_total {
      // width: 476px;
      height: 60px;
      font-size: 15px;
      font-weight: 400;
      color: #cccccc;
      line-height: 26px;
      margin-top: 30px;
    }
  }
  .right_text {
    width: 20%;
  }
}
// 尾部备案
.footes {
  // width: 1920px;
  height: 142px;
  background: #3a3a3a;
  .footes_bottom {
    // width: 766px;
    height: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #cccccc;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: center;
    padding: 47px 570px 47px 584px;
  }
}
</style>
