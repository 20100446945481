import Vue from "vue";
import VueRouter from "vue-router";
import cookies from "@/utils/cookie";
import { Message } from "element-ui";

import homeRouter from './modul/home.js'
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/index.vue"),
      meta: { title: "小灰鸽", keepAlive: false },
      children: [
        {
          path: "meiyan",
          component: () => import("@/views/home/meiyan/index.vue"),
          meta: { title: "美研首页", keepAlive: false },
        },
        {
          path: "yingguo",
          component: () => import("@/views/home/yingguo/index.vue"),
          meta: { title: "英国首页", keepAlive: false },
        },
        {
          path: "riben",
          component: () => import("@/views/home/riben/index.vue"),
          meta: { title: "日本首页", keepAlive: false },
        },
      ],
    },
    {
      path: "/haishi",
      name: "haishi",
      component: () => import("@/views/haishi/index.vue"),
      meta: { title: "优选海师", keepAlive: false },
      children: [
        {
          path: "",
          name: "haishiList",
          component: () => import("@/views/haishi/haishiList/index.vue"),
          meta: { title: "海师列表", keepAlive: false },
        },
        {
          path: "haishiDetails",
          name: "haishiDetails",
          component: () => import("@/views/haishi/haishiDetails/index.vue"),
          meta: { title: "海师详情", keepAlive: false },
        },
        {
          path: "haishiSub",
          name: "haishiSub",
          component: () => import("@/views/haishi/haishiSub/index.vue"),
          meta: { title: "预约海师", keepAlive: false },
        },
        {
          path: "haishiCase",
          name: "haishiCase",
          component: () => import("@/views/haishi/haishiCase/index.vue"),
          meta: { title: "成功案例", keepAlive: false },
        },
      ],
    },
    {
      path:'/teachlist',
      name:'/teachlist',
      component:()=>import('@/views/teach/teachList/teachlist.vue'),
      meta:{title:'研究者データ',keepAlive:false}
    },
    {
      path:'/teachdetai',
      name:'/teachlist',
      component:()=>import('@/views/teach/teachDetai/teachDetai.vue'),
      meta:{title:'研究者データ',keepAlive:false}
    },
    {
      path: "/personal",
      name: "personal",
      component: () => import("@/views/personal/Info.vue"),
      meta: { title: "个人信息", keepAlive: false,isLogin:true },
    },
    {
      path: "/sethead",
      name: "sethead",
      component: () => import("@/views/personal/Sethead.vue"),
      meta: { title: "修改头像", keepAlive: false,isLogin:true },
    },
    {
      path: "/order",
      name: "order",
      component: () => import("@/views/personal/order.vue"),
      meta: { title: "我的订单", keepAlive: false,isLogin:true },
    },
    {
      path: "/order2",
      name: "order",
      component: () => import("@/views/personal/order2.vue"),
      meta: { title: "我的订单2", keepAlive: false,isLogin:true },
    },
    {
      path: "/serviceEvalution",
      name: "serviceEvalution",
      component: () => import("@/views/personal/serviceEvalution.vue"),
      meta: { title: "服务评价", keepAlive: false ,isLogin:true},
    },
    {
      path: "/evalution",
      name: "evalution",
      component: () => import("@/views/personal/evalution.vue"),
      meta: { title: "评价详情", keepAlive: false,isLogin:true },
    },
    {
      path: "/writes",
      name: "writes",
      component: () => import("@/views/personal/writes.vue"),
      meta: { title: "写评价", keepAlive: false ,isLogin:true},
    },

    {
      path: "/bokkings",
      name: "bokkings",
      component: () => import("@/views/personal/bokkings.vue"),
      meta: { title: "订单详情", keepAlive: false,isLogin:true },
    },
    {
      path: "/financial",
      name: "financial",
      component: () => import("@/views/personal/caiwu/accounts.vue"),
      meta: { title: "统计核算", keepAlive: false ,isLogin:true},
    },
    {
      path: "/haishiaccount",
      name: "haishiaccount",
      component: () => import("@/views/personal/caiwu/haishiAccount.vue"),
      meta: { title: "海师统计", keepAlive: false,isLogin:true },
    },
    {
      path: "/customerAccount",
      name: "customerAccount",
      component: () => import("@/views/personal/caiwu/customerAccount.vue"),
      meta: { title: "客户统计", keepAlive: false,isLogin:true },
    },
    {
      path: "/haishidetail",
      name: "haishiDetail",
      component: () => import("@/views/personal/caiwu/haishiDetail.vue"),
      meta: { title: "海师详情", keepAlive: false ,isLogin:true},
    },
    {
      path: "/studentdetail",
      name: "studentDetail",
      component: () => import("@/views/personal/caiwu/studentDetail.vue"),
      meta: { title: "客户详情", keepAlive: false,isLogin:true },
    },
    //移动端h5
    {
      path: "/MhsDetail",
      name: "MhsDetail",
      component: () => import("@/views/Mobile/hsDetail.vue"),
      meta: { title: "海师详情", keepAlive: false },
    },
  ],
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // 我在这里模仿了一个获取用户信息的方法
  window.document.title = to.meta.title; // 设置title名
  // const Login = JSON.parse(window.localStorage.getItem("userInfo"));
  // console.log(to)
  if (to.meta.isLogin) {
    if (cookies.get("token")) {
      next()
    } else {
      next('/')
      Message.error({
        message: '未登录',
      });
    }
  } else {
    next()
  }
});

export default router;
