import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store' //vuex
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import 'normalize.css/normalize.css'// 清楚默认样式
import VueAwesomeSwiper from 'vue-awesome-swiper'  //引入swiper
import cookies from '@/utils/cookie'
import 'swiper/swiper-bundle.css'
import VideoPlayer from 'vue-video-player'   //视频播放插件
import 'vue-video-player/src/custom-theme.css'
import VueClipboard from 'vue-clipboard2'//复制粘贴插件
// import 'video.js/dist/video-js.css'
// import '_video.js@6.12.1@video.js/dist/video-js.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import animated from 'animate.css' //动画
Vue.use(animated)
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.$cookie = cookies;
Vue.use(ElementUI)
Vue.use(VueClipboard)
//全局过滤器
Vue.filter('-', function (value) {
  if (value==''||value==null||value=='空'||value=='无') return '-'
  return value
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
