/* eslint-disable no-undef */
<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="login" @close="close" :append-to-body='true' :close-on-click-modal='false'>
    <div slot="title" class="header-title">
      <span
        v-for="(item, key) in type"
        :key="key"
        @click="active = key"
        :class="{ act: active == key }"
        >{{ item }}</span
      >
    </div>
    <div class="code" v-show="active == 0">
      <div id="login_container"></div>
    </div>
    <div class="phone" v-show="active == 1">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="yzm">
          <el-input
            v-model="form.yzm"
            placeholder="验证码"
            clearable
          ></el-input>
          <img class="yzm" :src="imgsrc" @click="getImgScr" />
        </el-form-item>
        <el-form-item label="" prop="dxyzm">
          <el-input
            v-model="form.dxyzm"
            placeholder="短信验证码"
            clearable
          ></el-input>
          <div class="dxyzm" :class="{ forbidden: bState }" @click="gain()">
            {{ hq }}
          </div>
        </el-form-item>
        <div class="btn">
          <span @click="dialogVisible = false">取消</span>
          <span @click="Login">登录</span>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>
<script src="http://g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js"></script>
<script>
import { getCaptcha, getSend, getLogin } from "@/api/login.js";

export default {
  data() {
    return {
      imgsrc: null,
      imgkey: null,
      dialogVisible: true,
      active: 0,
      type: ["顾问老师", "学员/客户"],
      form: {
        phone: null, //手机号
        yzm: null, //验证码
        dxyzm: null, //短信验证码
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { len: 11, message: "请输入正确的手机号", trigger: "blur" },
          // { type: 'number', message: "请输入数字", trigger: "blur" },
        ],
        yzm: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        dxyzm: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      hq: "获取验证码",
      bState: false, //获取验证码
      timer: null, //图形验证码定时器
    };
  },
  methods: {
    //登录框关闭回调
    close() {
      // 调用父组件方法
      this.$store.state.login = false
    },
    //获取短信验证码
    gain() {
      let t, timer;
      if (this.bState) {
        return;
      }

      getSend({
        group_sms: "login",
        mobile: this.form.phone,
        captcha: this.form.yzm,
        key: this.imgkey,
      }).then((res) => {
        t = 59;
        this.hq = t + "s";
        clearInterval(timer);
        timer = setInterval(() => {
          this.bState = true;
          if (t == 1) {
            this.hq = "获取验证码";
            clearInterval(timer);
            this.bState = false;
          } else {
            t--;
            this.hq = t + "s";
          }
        }, 1000);
      });
    },
    //登录
    Login() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data;
          data = {
            group_user: "client",
            mobile: this.form.phone,
            group_sms: "login",
            code: this.form.dxyzm,
          };
          getLogin(data).then((res) => {
            console.log(11,res)
            if (res.code == 200) {
              this.$cookie.set("token", res.data.token);
              this.$cookie.set("infor", res.data.infor);
              this.$cookie.set("user_types", res.data.user_types);
              // 身份区分
                // 1管理员2未签约客户3已签约客户4前期咨询老师5后期服务老师 6运营老师 7财务老师 8前后期老师
              if(res.data.user_rule.length == 1){
                this.$cookie.set("user_rule", res.data.user_rule[0]);
              }else{
                this.$cookie.set("user_rule", 8);
              }

              location.reload();
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取钉钉登录二维码
    getcode() {
      const that = this;
      var url = encodeURIComponent(`${window.location.href}`);
      var source = this.$route.query.source;
      // var url = encodeURIComponent(`${window.location.origin}/#/login`);
      // console.log('url',url)
      var appid = "dingoazbksgi34qmalgykw";
      var goto = encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      );
      // eslint-disable-next-line no-undef
      var obj = DDLogin({
        id: "login_container", // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
        goto: goto, // 请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "325",
        height: "291",
      });
      var handleMessage = function(event) {
        var origin = event.origin;
        if (origin == "https://login.dingtalk.com") {
          var loginTmpCode = event.data;
          if (loginTmpCode) {
            // // console.log("loginTmpCode", loginTmpCode);
            let u = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=${source}&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`;
            window.location.href = u;
          }
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    //获取图形验证码
    getImgScr() {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        await getCaptcha().then((res) => {
          this.imgsrc = res.data.img;
          this.imgkey = res.data.key;
        });
      }, 300);
    },
  },
  mounted() {
    // let a = this.$cookie.get('infor')

    // console.log(typeof a)
    this.$nextTick(() => {
      this.getcode();
    });
    this.getImgScr();
  },
};
</script>

<style lang="scss" scopde>
.login {
  width: 450px !important;
  background: #ffffff;
  border-radius: 8px;
  .el-dialog__header {
    padding: 0px;
    height: 70px;
    border-bottom: 1px solid #ededed;
    .header-title {
      padding-top: 25px;
    }
    span {
      width: 50%;
      display: inline-block;
      font-size: 20px;
      font-weight: 400;
      color: #7b7b7b;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
    }
    span:nth-of-type(1) {
      border-right: 1px solid #ededed;
    }
    .act {
      color: #333333;
    }
    .el-dialog__headerbtn {
      // display: none;
      top: 10px;
      right: 10px;
    }
  }
  .el-dialog__body {
    padding: 25px 50px;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .code {
      #login_container {
        padding-bottom: 50px;
      }
    }
    .phone {
      .el-form {
        width: 100%;
        .el-form-item {
          margin-bottom: 25px;
        }
        .el-form-item__content {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          border: 1px solid #ededed;
          background: #fafafa;
          border-radius: 4px;
          box-sizing: border-box;
          position: relative;
          height: 40px;
          .el-input {
            flex: 1;
            .el-input__inner {
              border: 1px solid white;
              background: none;
            }
          }
          .yzm {
            height: 32px;
            margin: auto;
          }
          .dxyzm {
            width: 108px;
            height: 40px;
            background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
            text-align: center;
            position: relative;
            top: -1px;
            border-radius: 0px 2px 2px 0px;
            font-size: 15px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #935d14;
            cursor: pointer;
          }
          .forbidden {
            cursor: not-allowed;
          }
        }
        .btn {
          display: flex;
          justify-content: space-between;
          span {
            width: 150px;
            height: 50px;
            border-radius: 4px;
            box-sizing: border-box;
            display: inline-block;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
            position: relative;
          }
          span:active {
            opacity: 0.8;
            top: 1px;
          }
          span:nth-of-type(1) {
            border: 1px solid #ededed;
            color: #999999;
          }
          span:nth-of-type(2) {
            background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
            color: #935d14;
          }
        }
      }
    }
  }
}
</style>
