import axios from "axios";
import cookies from "@/utils/cookie";
import { Message } from "element-ui";
import { getToken } from "@/api/login";
// 创建一个错误
function errorCreate(msg) {
  const error = new Error(msg);
  throw error;
}

// api本地开发接口地址
// const api = "http://hs/"; // 赵凯
// const api = 'http://mentor.hi-daas.net' //李剑
const api = "http://192.168.60.41:82"; //李剑
// const api = "http://www.hs.com"; //文辉
// const api = "http://192.168.60.77:801"; //文辉
// const api = "https://mentorapi.hi-daas.com"; //本地环境使用线上

// 创建一个 axios 实例
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? api
      : "https://mentorapi.hi-daas.com/",
  // timeout: 5000  //请求超时时间
});

// 是否正在刷新token的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let retryRequests = [];

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    const token = `Bearer ${cookies.get("token")}`;
    // const token = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9oc1wvYXBpXC9hdXRoXC9sb2ctaW4iLCJpYXQiOjE2MTkzMTc3NzAsImV4cCI6MTYxOTY3Nzc3MCwibmJmIjoxNjE5MzE3NzcwLCJqdGkiOiJPUzNKdWo5cDFaN0ZLcmsxIiwic3ViIjo3LCJwcnYiOiI2MDBlN2MyMWQ3NDY0M2E4OGFhZDRhMDA0YjY5NTE1YTFhYjczOTdiIiwidXNlcl90eXBlcyI6ImNjdXNlciIsInVzZXJfcnVsZSI6WzddfQ.6dy4WroS32QAfFNxCZUKux-WPOKbaXtIrWiWqnf40jA`
    // cookies.set('source','meiyan')
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    if (cookies.get("token")) {
      config.headers.Authorization = token;
    }
    const source = cookies.get("source");
    // config.headers.Source = source;
    // config.headers.Source = "yingguo";
    config.headers.Source = source;
    return config;
  },
  (error) => {
    // 发送失败
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  async (response) => {
    // console.log("response", response);
    var doms2 = document.getElementsByClassName("el-message")[0];
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const code = dataAxios.code;
    if (code == 200) {
      return dataAxios;
    } else {
      // 其他状态码
      switch (code) {
        // 未登录
        case 496:
          if (doms2 == undefined) {
            Message.error({
              message: "未登录",
            });
          }
          break;
        //token完全过期无法刷新
        case 499:
          //token完全过期无法刷新
          if (doms2 == undefined) {
            Message.error({
              message: "登录失效，请重新登录",
            });
          }
          //清除登录状态
          cookies.remove("infor");
          cookies.remove("token");
          break;
          //刷新token
        case 401:
          //判断是否正在更新token
          if (!isRefreshing) {
            isRefreshing = true; //更新状态
            //发起刷新token
            await getToken({}).then((res) => {
              if (res.code == 200) {
                cookies.set("token", res.data); //刷新成功
                //遍历缓存队列 发起请求 传入最新token
                retryRequests.forEach((cb) => cb(res.data));
                // 重试完清空这个队列
                retryRequests = [];
                service(response.config);
              } else {
                //刷新失败 重新登录
                if (doms2 == undefined) {
                  Message.error({
                    message: "登录失效，请重新登录",
                  });
                }
                //清除登录状态
                cookies.remove("infor");
                cookies.remove("token");
              }
            });
            // 正在刷新token，返回一个未执行resolve的promise
          } else {
            return new Promise((resolve) => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              retryRequests.push((token) => {
                response.config.headers.Authorization = token;
                resolve(service(response.config));
              });
            });
          }
          break;
        default:
          // 其他错误
          if (doms2 == undefined) {
            Message.error({
              message: dataAxios.msg,
            });
          }
          errorCreate(`${dataAxios.msg}: ${response.config.url}`);
          break;
      }
      errorCreate(
        `错误抛出[ code: ${code} ] ${dataAxios.msg}: ${response.config.url}`
      );
    }
  },
  //错误状态码
  async (error) => {
    var response = error.response;
    var doms2 = document.getElementsByClassName("el-message")[0];
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        //刷新token
        case 401:
          //判断是否正在更新token
          if (!isRefreshing) {
            isRefreshing = true; //更新状态
            //发起刷新token
            await getToken({}).then((res) => {
              if (res.code == 200) {
                cookies.set("token", res.data); //刷新成功
                //遍历缓存队列 发起请求 传入最新token
                retryRequests.forEach((cb) => cb(res.data));
                // 重试完清空这个队列
                retryRequests = [];
                service(response.config);
              } else {
                //刷新失败 重新登录
                if (doms2 == undefined) {
                  Message.error({
                    message: "登录失效，请重新登录",
                  });
                }
                //清除登录状态
                cookies.remove("infor");
                cookies.remove("token");
              }
            });
            // 正在刷新token，返回一个未执行resolve的promise
          } else {
            return new Promise((resolve) => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              retryRequests.push((token) => {
                response.config.headers.Authorization = token;
                resolve(service(response.config));
              });
            });
          }
          break;
        case 402:
          Message.error({
            message: response.data.msg,
          });
          break;
        case 499:
          //token完全过期无法刷新
          if (doms2 == undefined) {
            Message.error({
              message: "登录失效，请重新登录",
            });
          }
          //清除登录状态
          cookies.remove("infor");
          cookies.remove("token");
          break;
        // 未登录
        case 496:
          if (doms2 == undefined) {
            Message.error({
              message: "未登录",
            });
          }
          break;
        //账号在别处登录
        case 498:
          Message.error({
            message: response.data.msg,
          });
          //清除登录状态
          cookies.remove("infor");
          cookies.remove("token");
          break;
        default:
          //其他错误
          if (doms2 == undefined) {
            Message.error({
              message: response.data.msg,
            });
          }
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
