import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
var store = new Vuex.Store({
  state:{
    search:'',//列表搜索框
    login: false, //登录弹窗
    currentUrl:window.location.origin,//当前URL前缀
  },
  
})
export default store