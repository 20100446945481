import request from '@/utils/axios'
// 图形验证码
export function getCaptcha (data) {
  return request({
    url: '/api/captcha',
    method: 'get',
    data
  })
}
// 获取短信验证码
export function getSend (data) {
  return request({
    url: '/api/send-sms',
    method: 'post',
    data
  })
}
// 登录
export function getLogin (data) {
  return request({
    url: '/api/auth/log-in',
    method: 'post',
    data
  })
}

// 测试登录
export function testGetLogin (data) {
  return request({
    url: '/api/auth/log-in',
    method: 'post',
    data
  })
}

// 刷新token
export function getToken (data) {
  return request({
    url: '/api/auth/refresh',
    method: 'post',
    data
  })
}
