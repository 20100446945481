<template>
  <div id="app">
    <Header
      v-if="isHeaderShow"
      :isHome="isHome"
      :isLogin="isLogin"
      :infor="infor"
    ></Header>
    <router-view :test2="false" v-if="isRouterAlive"></router-view>
    <!-- <div>尾部</div> -->
    <Footer v-if="!isHome"> </Footer>
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer.vue";
import { getLogin } from "@/api/login.js";
import { Bus } from "@/views/home/components/bus.js";
export default {
  name: "App",
  // components:{
  //   appFooter
  // },
  data() {
    return {
      list: [],
      isHeaderShow: true,
      isHome: false,
      isLogin: false,
      infor: {},
      isRouterAlive: true,
    };
  },
  components: {
    Header,
    Footer,
  },
  watch: {
    $route() {
      //如果URL上有code并且是未登录状态   请求登录接口
      if (this.$route.query.code && !this.$cookie.get("token")) {
        getLogin({
          group_user: "ccuser",
          code: this.$route.query.code,
        }).then((res) => {
          if (res.code == 200) {
            this.$cookie.set("token", res.data.token);
            this.$cookie.set("infor", res.data.infor);
            this.$cookie.set("user_types", res.data.user_types);
            // 身份区分
            // 1管理员2未签约客户3已签约客户4前期咨询老师5后期服务老师 6运营老师 7财务老师 8前后期老师
            if(res.data.user_rule.length == 1){
              this.$cookie.set("user_rule", res.data.user_rule[0]);
            }else{
              this.$cookie.set("user_rule", 8);
            }
            location.reload()
          }
        }).catch(err =>{
          //异常 清楚URL上面的code
          this.$router.push({ query: {} });
        });
      }
      if (this.$route.matched[0].path === "/home"||this.$route.path === "/MhsDetail") {
        this.isHome = true;
      } else {
        this.isHome = false;
        this.isHeaderShow = true;
      }
    },
    infor(val) {
      this.isLogin = val ? true : false;
    },
  },
  mounted() {
    this.infor = this.$cookie.get("infor")
      ? JSON.parse(this.$cookie.get("infor"))
      : null;
  },
  methods: {
    changeHeaderShow(val) {
      this.isHeaderShow = val;
    },
  },
  created() {
    Bus.$on("changeHeaderShow", this.changeHeaderShow);
  },
};
</script>

<style>
@import '~@/assets/css/mixin.scss';
@font-face {
  /* font-family: "japanFont";
  src: url('./views/home/riben/assets/font/Noto.woff'); */
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* //优化移动端页面下 提示框 字体过大 */
@media screen and (max-width: 500px) {
    .el-message {
      min-width: 300px !important;
      font-size: 12px;
    }
}
</style>
